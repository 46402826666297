import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
export default function MenuLink() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuIcon
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          background: "white",
          color: "black",
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          size: "30px",
        }}
        PaperProps={{
          style: {
            backgroundColor: "black",
            color: "white",
            boxShadow: "none",
            border: "1px solid white",
            // width: '150px',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/");
            handleClose();
          }}
        >
          Home
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/about");
            handleClose();
          }}
        >
          About
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/services");
            handleClose();
          }}
        >
          Services
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/posts");
            handleClose();
          }}
        >
          Blogs
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/loan");
            handleClose();
          }}
        >
          Loan
        </MenuItem>
      </Menu>
    </div>
  );
}
