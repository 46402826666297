import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./post.css";

export const Posts = () => {
  const [data, setData] = useState([]);

  const [lastObject, setLastObject] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    const URL = "https://www.aadhyacarloans.com/api/blogposts";

    try {
      const response = await fetch(URL);
      const dat = await response.json();
      setData(dat);
      if (dat.length > 0) {
        setLastObject(dat.slice(-3));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(data);
  const redirectToBlogPage = (blogId, blog) => {
    window.scrollTo(0, 0);
    navigate(`/blog/${blogId}`, { state: { blog } });
  };
  return (
    <div className="posts">
      <div className="main">
        <p className="aadya">AAdhya Associates</p>
      </div>
      <div className="blog-services">
        <h1 className="whowe">
          Our <strong style={{ background: "#407BFF" }}>Blog Posts</strong>
        </h1>
        <div className="blogs-service">
          {lastObject.map((blog, index) => (
            <div
              className="blog1-service"
              key={index}
              onClick={() => redirectToBlogPage(blog.id, blog)}
            >
              <img src={blog.image} alt="blog" />
              <h1>{blog.title}</h1>
              <p className="date">
                {blog.posted_date.split(" ").slice(0, 4).join(" ")}
              </p>
              <p className="blog1-desc">
                {blog.content.split(" ").slice(0, 25).join(" ")}...
              </p>
              <button className="blog-button">Read more</button>
            </div>
          ))}
        </div>
      </div>
      <div className="previous-blogs">
        <h1 className="whowe">
          Our <strong style={{ background: "#407BFF" }}>previous Posts</strong>
        </h1>

        <div className="recent-ones">
          {data.map((blog, index) => (
            <div className="previous-one" key={index}>
              <img src={blog.image} alt="previous" />
              <p className="name-blog">{blog.title}</p>
              <p className="date-blog">
                {blog.posted_date.split(" ").slice(0, 4).join(" ")}
              </p>
              <p className="desc-blog">
                {blog.content.split(" ").slice(0, 25).join(" ")}...
              </p>
              <button className="blog-button" onClick={() => redirectToBlogPage(blog.id, blog)}  >Read more </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
