import React from "react";
import { useFormikContext } from "formik";

const UploadFiles = ({ formField }) => {
  const { setFieldValue } = useFormikContext();

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue(fieldName, file);
    }
  };

  const renderFileInput = (label, name, accept, fieldName) => (
    <>
      <label>{label}</label>
      <input
        type="file"
        accept={accept}
        name={name}
        onChange={(e) => handleFileChange(e, fieldName)}
      />
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "90%",
        gap: "20px",
        marginLeft: "5%",
      }}
    >
      {renderFileInput(
        "AAdhar Card. Valid Government ID Card. Front and Back side. Original ID Card. Full photo. All parts of the ID card must be shown. The four corners of the ID card must show on the photo. ID must be original and valid. ID card must be very clear. (Please upload file) as image png*",
        "image",
        "image/*",
        formField.image.name
      )}
      {renderFileInput(
        "Pan Card. Valid Government ID Card. Front and Back side. Original ID Card. Full photo. All parts of the ID card must be shown. The four corners of the ID card must show on the photo. ID must be original and valid. ID card must be very clear. (Please upload file) as doc*",
        "document",
        ".doc, .docx",
        formField.document.name
      )}
      {renderFileInput(
        "Driving Licence Card. Valid Government ID Card. Front and Back side. Original ID Card. Full photo. All parts of the ID card must be shown. The four corners of the ID card must show on the photo. ID must be original and valid. ID card must be very clear. (Please upload file) as Pdf*",
        "pdf",
        "application/pdf",
        formField.pdf.name
      )}
    </div>
  );
};

export default UploadFiles;
