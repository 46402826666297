import React from "react";

export const BtnIcons8 = ({ className }) => {
  return (
    <svg
      className={`btn-icons-8 ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.21875 10.0846L10.0104 4.29297"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15833"
      />
      <path
        className="path"
        d="M4.21875 4.29297H10.0104V10.0846"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15833"
      />
    </svg>
  );
};
