import React from "react";
import { useWindowWidth } from "../../breakPoint";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import MenuLink from "../MenuLink/MenuLink";
import aadhyalogo from "../../assets/images/aadhyalogo.svg";
import aadhyalog from "../../assets/images/logowhite.png";
export const Header = () => {
  const screenWidth = useWindowWidth();
  const navigate = useNavigate()

  return (
    <div
      className="frame"
      style={{
        backgroundColor:
          screenWidth < 768
            ? "#ffffff"
            : screenWidth >= 1440 ||
              screenWidth <= 1440 ||
              (screenWidth >= 768 && screenWidth < 1440)
            ? "transparent"
            : undefined,
      }}
    >
      <div
        className="index"
        style={{
          backgroundColor: screenWidth < 768 ? "#ffffff" : undefined,
          height:
            screenWidth < 768
              ? "80px"
              : screenWidth >= 768 && screenWidth < 1440
              ? "84.76px"
              : screenWidth >= 1440
              ? "154px"
              : undefined,
          overflow:
            screenWidth >= 768 && screenWidth < 1440 ? "hidden" : undefined,
          position:
            screenWidth >= 1440 || screenWidth <= 1440 || screenWidth < 768
              ? "relative"
              : undefined,
          width:
            screenWidth < 768
              ? "100%"
              : screenWidth >= 768 && screenWidth < 1440
              ? "100%"
              : screenWidth >= 1440
              ? "100%"
              : undefined,
        }}
      >
        {screenWidth < 768 && (
          <>
            <div className="div">
              <div className="rectangle" />
              <div className="div-2">
                <div className="group">
                  <div className="symbol">
                    <MailOutlinedIcon
                      sx={{ width: "10px", marginTop: "-5px" }}
                    />
                  </div>
                  <div className="section-email">theaadhyaassociates@gmail.com</div>
                </div>
                <div className="group-2">
                  <div className="text-wrapper">
                    <PhoneEnabledOutlinedIcon
                      sx={{
                        width: "13px",
                        height: "28px",
                        marginTop: "-15px",
                        marginLeft: "-15px",
                      }}
                    />
                  </div>
                  <div className="section">087902 62442</div>
                </div>
                <div className="group-3">
                  <div className="symbol-2">
                    <a
                      style={{
                        listStyle: "none",
                        color: "white",
                      }}
                      href="https://www.facebook.com/theaadhyaassociates"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookOutlinedIcon
                        style={{
                          width: "22px",
                          marginTop: "-8px",
                          marginLeft: "-32px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="symbol-3">
                    <a
                      style={{
                        listStyle: "none",
                        color: "white",
                      }}
                      href="https://www.instagram.com/theaadhyaassociates/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon
                        style={{
                          width: "22px",
                          marginTop: "-8px",
                          marginLeft: "22px",
                        }}
                      />
                    </a>
                  </div>
                  <div className="symbol-4">
                    <a
                      style={{
                        listStyle: "none",
                        color: "white",
                      }}
                      href="https://www.linkedin.com/company/aadhya-associate/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon
                        style={{
                          width: "22px",
                          marginTop: "-8px",
                          marginLeft: "-41px",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-3">
              <img
                className="img"
                alt="Frame"
                src="https://cdn.animaapp.com/projects/6597b421626395d655ed1a46/releases/6597b444d85112d6345589a4/img/frame-1686561940.svg"
              />
              <div className="div-4">
                <img
                  className="aadhya-associates"
                  alt="Aadhya associates"
                  src={aadhyalogo}
                  onClick={()=>navigate("/")}
                />
                <div className="header-btn">
                  <HeadsetMicIcon sx={{ color: "white", fontSize: "14px" }} />
                  <a
                    href="tel:8790262442"
                    style={{ textDecoration: "none" }}
                    className="btn-text"
                  >
                    Register Now
                  </a>
                </div>
                <MenuLink />
              </div>
            </div>
          </>
        )}

        {screenWidth >= 768 && screenWidth < 1440 && (
          <div className="overlap">
            <div className="navbar">

              <div className="navbar-contact">
                <a style={{color: "white"}}> <MailOutlinedIcon sx ={{fontSize: "1rem"}}/> theaadhyaassociates@gmail.com</a>
                <a style={{color: "white"}}> <PhoneEnabledOutlinedIcon sx ={{fontSize: "1rem"}}/> 087902 62442</a>
              </div>


              <div className="symbol-7">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.facebook.com/theaadhyaassociates"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookOutlinedIcon />
                </a>
              </div>
              <div className="symbol-8">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.instagram.com/theaadhyaassociates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
              </div>
              <div className="symbol-9">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.linkedin.com/company/aadhya-associate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            <header className="header">
              <div className="row">
                <div className="overlap-group">
                  <div className="agencify-logo">
                    <div className="stars" />
                  </div>
                  <div className="menu-btn">
                    <div className="menu-list">
                      <Link
                        className="link"
                        divClassName="instance-node"
                        text="Home"
                        to="/"
                      >
                        Home
                      </Link>
                      <Link className="link" text="About" to="/about">
                        About
                      </Link>
                      <Link className="link" text="About" to="/services">
                        Services
                      </Link>
                      <Link
                        className="link"
                        divClassName="instance-node"
                        text="Blog Post"
                        to="/posts"
                      >
                        Blog post
                      </Link>
                      <Link className="link" text="About" to="/loan">
                        Eligibility
                      </Link>
                    </div>
                    <div className="header-btn-2">
                      < HeadsetMicIcon sx={{color:"white"}} />
                      
                      <div className="btn-text-2">
                        {" "}
                        <a
                          href="tel:8790262442"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          Request Call Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="logo">
                <img
                  className="aadhya-associates-2"
                  alt="Aadhya associates"
                  src={aadhyalog}
                  onClick={()=>navigate("/")}
                  />
              </div>
            </header>
          </div>
        )}

        {screenWidth >= 1440 && (
          <>
            <div className="navbar-2">
              <div className="symbol-10">
                <MailOutlinedIcon />
              </div>
              <div className="section-email-3">theaadhyaassociates@gmail.com</div>
              <div className="symbol-11">
                <PhoneEnabledOutlinedIcon />
              </div>
              <div className="section-3">087902 62442</div>
              <div className="symbol-12">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.facebook.com/theaadhyaassociates"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookOutlinedIcon />
                </a>
              </div>
              <div className="symbol-13">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.instagram.com/theaadhyaassociates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </a>
              </div>
              <div className="symbol-14">
                <a
                  style={{ listStyle: "none", color: "white" }}
                  href="https://www.linkedin.com/company/aadhya-associate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
            <header className="header-2">
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <img
                    className="agencify-logo-2"
                    alt="Agencify logo"
                    src="https://cdn.animaapp.com/projects/6597b421626395d655ed1a46/releases/6597b444d85112d6345589a4/img/rectangle-4319-1@2x.png"
                  />
                  <div className="menu-btn-2">
                    <div className="menu-list-2">
                      <Link className="link-2" text="Home" to="/">
                        Home
                      </Link>
                      <Link
                        className="link-2"
                        color="white"
                        text="About"
                        to="/about"
                      >
                        About
                      </Link>
                      <Link className="link" text="About" to="/services">
                        Services
                      </Link>
                      <Link
                        className="link-2"
                        divClassName="link-3"
                        text="Blog Post"
                        to="/posts"
                      >
                        Blog Post
                      </Link>
                      <Link
                        className="link-2"
                        divClassName="link-3"
                        text="Blog Post"
                        to="/loan"
                      >
                        Eligibility
                      </Link>
                    </div>
                    <div className="header-btn-3">
                      <div className="group-wrapper">
                        <HeadsetMicIcon sx={{ color: "white" }} />
                      </div>
                      <button className="btn-text-3">
                        {" "}
                        <a
                          href="tel:8790262442"
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          Request Call Now
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <img className="logo-2" alt="Logo" src={aadhyalog} onClick={()=>navigate("/")} />
            </header>
          </>
        )}
      </div>
    </div>
  );
};
