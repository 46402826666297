import React, { useState } from "react";
import "./loan.css";
import loann from "./loan.png";
import steering from "./steering.png";
import Box from "@mui/material/Box";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Formik, Form } from "formik";

import LoanDetails from "../../components/Forms/LoanDetails";
import PersonalDetails from "../../components/Forms/PersonalDetails";
import UploadSuccess from "../../components/UploadSuccess/UploadSuccess";

import validationSchema from "../../components/FormModal/validationSchema";
import CheckoutModal from "../../components/FormModal/CheckoutModal";
import formInitialValues from "../../components/FormModal/formInitialValues";
import UploadFiles from "../../components/Forms/UploadFiles";

const Loan = () => {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];

  const { formId, formField } = CheckoutModal;

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <LoanDetails formField={formField} selectedTab={selectedTab} />;
      case 1:
        return <PersonalDetails formField={formField} />;
      case 2:
        return <UploadFiles formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  const steps = ["Loan Details ", "Personal Details", "Documents Upload"];
  const isLastStep = activeStep === steps.length - 1;

  async function _submitForm(values, actions) {
    const URL = "https://www.aadhyacarloans.com/api/apply";
    try {
      console.log("Form values:", values);

      const formData = new FormData();

      for (const [key, value] of Object.entries(values)) {
        if (value instanceof File) {
          formData.append(key, value);
        } else if (Array.isArray(value)) {
          value.forEach((item) => formData.append(key, item));
        } else {
          formData.append(key, value);
        }
      }

      console.log("Form data before fetch:", formData);

      const response = await fetch(URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = `Request failed with status ${response.status}: ${response.statusText}`;
        throw new Error(errorMessage);
      }

      const responseData = await response.json();
      console.log("Form data submitted successfully:", responseData);
      setActiveStep(activeStep + 1);
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      actions.setSubmitting(false);
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
  console.log(isLastStep);
  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    console.log(tab);
  };

  return (
    <div className="loan">
      <div className="loan-container">
        <div className="sec">
          <img src={loann} alt="app" />
        </div>
        <div className="fom">
          <div className="tabs">
            <div
              className="tab-1"
              onClick={() => handleTabClick(1)}
              style={selectedTab === 1 ? { background: "#407BFF" } : {}}
            >
              <img src={steering} alt="steering" />
              <p className="p-head">Used Car Purchase Loan</p>
            </div>
            <div
              className="tab-1"
              onClick={() => handleTabClick(2)}
              style={selectedTab === 2 ? { background: "#407BFF" } : {}}
            >
              <img src={steering} alt="steering" />
              <p className="p-head">Auto Loan/ New Car Loan</p>
            </div>
            <div
              className="tab-1"
              onClick={() => handleTabClick(3)}
              style={selectedTab === 3 ? { background: "#407BFF" } : {}}
            >
              <img src={steering} alt="steering" />
              <p className="p-head">Used Car Re-Finance Loan</p>
            </div>
          </div>
          <div className="step">
            <Box className="stepp">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}

                  >
                    <StepLabel
                  
                  sx = {
                    {
                      "& span":{
                        color: "white",
                      },
                      "& span .Mui-active":{
                        color: "white"
                      },
                      "& span .Mui-completed":{
                        color: "green"
                      }
                    }
                  }
                    >{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
          <div className="main-form">
            <div className="left-sec">
              <div className="left-cont">
                <h3 className="our">Our Interest Rates</h3>
                <p className="road">
                  On Road <strong className="stero">90%</strong> On Valuation [
                  <strong className="stero">Upto 7yrs</strong>]
                </p>
                <p className="road">
                  Used Car<strong className="stero">90%</strong> On Valuation [
                  <strong className="stero">Upto 5 yrs</strong>]
                </p>
                <p className="road">
                  Used Refinement<strong className="stero">90%</strong> On
                  Valuation [<strong className="stero">Upto 5 yrs</strong>]
                </p>
              </div>
            </div>
            <div className="right-sec">
              <React.Fragment>
                {activeStep === steps.length ? (
                  <UploadSuccess />
                ) : (
                  <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form id={formId} encType="multipart/form-data">
                        {_renderStepContent(activeStep)}

                        <div className="btnsub">
                          {activeStep !== 0 && (
                            <Button onClick={_handleBack} className="btnback">
                              Back
                            </Button>
                          )}
                          <div className="man-oh">
                            <Button
                              disabled={isSubmitting}
                              sx={{background:"#407BFF"}}
                              type="submit"
                              variant="contained"
                              className="btnsubu"
                            >
                              {isLastStep ? "Submit" : "Next"}
                            </Button>
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                              />
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loan;
