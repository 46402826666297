import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField, SelectField } from "../FormFields";
import "./personal.css";
const sing = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "married",
    label: "Married",
  },
];
export default function PersonalDetails(props) {
  const {
    formField: {
      firstName,
      lastName,
      phoneNumber,
      single,
      dob,
      email,
      address,
      state,
      zipcode,
      city,
    },
  } = props;

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Grid
          xs={12}
          md={6}
          className="mobile-details"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: "70%",
            rowGap: "10px",
            height: "250px",
            marginTop: "30px",
          }}
        >
          <Grid item xs={12} md={6} className="wdh">
            <InputField
              name={firstName.name}
              placeholder={firstName.label}
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="wdh">
            <InputField
              name={lastName.name}
              placeholder={lastName.label}
              fullWidth
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="wdh">
            <InputField
              name={dob.name}
              placeholder={dob.label}
              fullWidth
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="wdh">
            <InputField
              name={email.name}
              placeholder={email.label}
              fullWidth
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="wdh">
            <SelectField
              name={single.name}
              label={single.label}
              data={sing}
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} className="wdh">
            <InputField
              name={phoneNumber.name}
              placeholder={phoneNumber.label}
              fullWidth
              style={{
                width: "250px",
                height: "50px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{ minWidth: "65%", padding: 0 }}
          className="present-address"
        >
          <InputField
            name={address.name}
            placeholder={address.label}
            fullWidth
            style={{
              width: "90%",
              height: "50px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{ minWidth: "65%", marginTop: 5, display: "flex", gap: "10px" }}
          className="states-city"
        >
          <InputField
            name={state.name}
            placeholder={state.label}
            fullWidth
            style={{
              width: "150px",
              height: "50px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
          <InputField
            name={city.name}
            placeholder={city.label}
            fullWidth
            style={{
              width: "150px",
              height: "50px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
          <InputField
            name={zipcode.name}
            placeholder={zipcode.label}
            fullWidth
            style={{
              width: "150px",
              height: "50px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
