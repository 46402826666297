import CheckoutModal from "./CheckoutModal";
const {
  formField: {
    loanType,
    loanAmount,
    carName,
    position,
    loanTenure,
    vechileDetails,
    firstName,
    lastName,
    phoneNumber,
    single,
    dob,
    email,
    address,
    image,
    document,
    pdf,
    state,
    zipcode,
    city,
  },
} = CheckoutModal;

export default {
  [loanType.name]: "newcarloan",
  [position.name]: "",
  [carName.name]: "",
  [loanAmount.name]: "",
  [loanTenure.name]: "",
  [vechileDetails.name]: "",
  [firstName.name]: "",
  [lastName.name]: "",
  [address.name]: "",
  [state.name]: "",
  [zipcode.name]: "",
  [city.name]: "",
  [phoneNumber.name]: "",
  [dob.name]: "",
  [email.name]: "",
  [single.name]: "single",
  [image.name]: "",
  [document.name]: "",
  [pdf.name]: "",
};
