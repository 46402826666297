import React, { useState } from "react";
import "./aadhya_footer.css";
import aadhyalogo from "../../assets/images/aadhyalogo.svg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
const Footer = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {

    e.preventDefault();
    var formData = new FormData(document.getElementById('upload_form'));

    
    console.log('********************');
    console.log(formData);

    fetch('https://www.aadhyacarloans.com/api/subscribe', {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      body: formData, //JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setEmail("");

  };
console.log(email);
  return (
    <div className="aadhya-container">
      <div className="aadya-footer-container">
        <div className="footer-img">
          <div className="footer-logo">
            <img src={aadhyalogo} alt="" />
            <p>
              Reinventing the way of creating websites, we aim to create the
              most master-peaced WordPress theme available on the market.
            </p>
          </div>

          <div className="social-links">
            <h5>Social Links</h5>
            <div className="links">
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.facebook.com/theaadhyaassociates"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlinedIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-20px",
                  }}
                />
              </a>
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.instagram.com/theaadhyaassociates/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-15px",
                  }}
                />
              </a>
              <a
                style={{
                  listStyle: "none",
                  color: "white",
                }}
                href="https://www.linkedin.com/company/theaadhyaassociate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginLeft: "-15px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-container">
          <h5 className="ch5">Contact Us</h5>
          <ul>
            <li>Near meetings restaurant, </li>
            <li>Visalakshi Nagar, </li>
            <li>Visakhapatnam,Ap </li>
            <li>
              Call Us: <span>8790262442</span>
            </li>
            <li>theaadhyaassociates@gmail.com</li>
          </ul>
        </div>

        <div className="sineup-maps-container">
          <div className="sign-up">
            <h5>Sign Up for Email Updates</h5>
            <form onSubmit={handleFormSubmit} id="upload_form">
        <input
          className="text"
          required
          type="email"
          name="email"
          value={email}
          placeholder="Your e-mail address"
          onChange={handleInputChange}
        />
        <button type="submit">
          Get Back
        </button>
      </form>
            <div className="sign-para">
              <p>Sign up with your email address to receive news and updates</p>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  className="gmap_iframe"
                  width="420px"
                  height="135px"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  id="gmaps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1899.8715035714617!2d83.33638623776851!3d17.756744250230792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395bf613d04293%3A0x6c5654eb4486871c!2sAADHYA%20ASSOCIATES%20-%20Used%20Car%20Loans%20%7C%20Car%20Loans%20%7C%20Four%20Wheeler%20Loans%2C%20New%20Car%20Loans%20in%20Vizag!5e0!3m2!1sen!2sin!4v1710308640419!5m2!1sen!2sin"
                >d</iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hr-container">
        <div>
          <hr />
        </div>
        <div className="copy-rights">
          <span>Copyright ©2023. All rights reserved.</span>
          <ul>
            <Link
              className="link"
              divClassName="instance-node"
              text="Home"
              to="/"
            >
              Home
            </Link>
            <Link className="link" text="About" to="/about">
              About
            </Link>
            <Link className="link" text="About" to="/services">
              Services
            </Link>
            <Link
              className="link"
              divClassName="instance-node"
              text="Blog Post"
              to="/posts"
            >
              Blog post
            </Link>
            <Link className="link" text="About" to="/loan">
              Eligibility
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
