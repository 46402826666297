import React from "react";
import { Typography } from "@mui/material";
import upload from "./Animation - 1703154357352.gif";
function UploadSuccess() {
  return (
    <React.Fragment>
      <img src={upload} alt="upload" />
      <Typography variant="h5" gutterBottom>
        We will get back to you
      </Typography>
    </React.Fragment>
  );
}

export default UploadSuccess;
