export default {
  formId: "checkoutForm",
  formField: {
    loanType: {
      name: "loanType",
      label: "Loan Type*",
      requiredErrorMsg: "Loan type is required",
    },
    position: {
      name: "position",
      label: "Position*",
      requiredErrorMsg: "Position is required",
    },
    carName: {
      name: "carName",
      label: "CarName*",
      requiredErrorMsg: "CarName is required",
    },
    loanAmount: {
      name: "loanAmount",
      label: "LoanAmount",
      requiredErrorMsg: "loanAmount is required",
      invalidErrorMsg: "Loan Amount is not valid (e.g. 70000)",
    },

    loanTenure: {
      name: "loanTenure",
      label: "Loan Tenure",
      options: [
        { value: "twelvemonths", label: "12 months" },
        { value: "eighteenmonths", label: "18 months" },
        { value: "twentyfourmonths", label: "24 months" },
        { value: "thirtysixmonths", label: "36 months" },
        { value: "fourtyeightmonths", label: "48 months" },
      ],
      requiredErrorMsg: "Loan Tenure is required",
    },
    vechileDetails: {
      name: "type",
      label: "type like [Manufacture Name,Model]",
      requiredErrorMsg: "type is required",
    },
    firstName: {
      name: "firstName",
      label: "First name*",
      requiredErrorMsg: "First name is required",
    },
    lastName: {
      name: "lastName",
      label: "Last name*",
      requiredErrorMsg: "Last name is required",
    },
    dob: {
      name: "dob",
      label: "mm-dd-yyyy",
      requiredErrorMsg: "Dob is required",
    },
    email: {
      name: "email",
      label: "Email*",
      requiredErrorMsg: "Email is required",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Phone Number*",
      requiredErrorMsg: "Phone Number is required",
    },
    address: {
      name: "address",
      label: "Present Address*",
      requiredErrorMsg: "Address  is required",
    },
    city: {
      name: "city",
      label: "City*",
      requiredErrorMsg: "City is required",
    },
    state: {
      name: "state",
      label: "State/Province/Region",
    },
    zipcode: {
      name: "zipcode",
      label: "Zipcode*",
      requiredErrorMsg: "Zipcode is required",
      invalidErrorMsg: "Zipcode is not valid (e.g. 70000)",
    },
    single: {
      name: "single",
      label: "Single",
      requiredErrorMsg: "Gender is required",
    },
    image: {
      name: "image",
      accept: "image/*", // Add accept attribute if necessary
    },
    document: {
      name: "document",
      accept: ".doc, .docx",
    },
    pdf: {
      name: "pdf",
      accept: "application/pdf",
    },
  },
};
