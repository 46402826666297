import React, { useState } from 'react';
import axios from 'axios';

function FormComponent() {
  const [formData, setFormData] = useState({
    textInput1: '',
    textInput2: '',
    fileInput: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      fileInput: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('t1', formData.textInput1);
    formDataToSend.append('t2', formData.textInput2);
    formDataToSend.append('f1', formData.fileInput);
    console.log(formData);
    try {
      const response = await axios.post('https://www.aadhyacarloans.com/test', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Form submitted successfully!', response.data);
      // Reset form fields if needed
      setFormData({
        textInput1: '',
        textInput2: '',
        fileInput: null
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="textInput1">Text Input 1:</label>
        <input
          type="text"
          id="textInput1"
          name="textInput1"
          value={formData.textInput1}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="textInput2">Text Input 2:</label>
        <input
          type="text"
          id="textInput2"
          name="textInput2"
          value={formData.textInput2}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="fileInput">File Input:</label>
        <input
          type="file"
          id="fileInput"
          name="fileInput"
          onChange={handleFileChange}
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default FormComponent;
