import React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { InputField, SelectField } from "../FormFields";
import { useFormikContext } from "formik";

const cities = [
  
  {
    value: "salaried",
    label: "Salaried",
  },
  {
    value: "self Employeed",
    label: "self Employeed",
  },
  {
    value: "unemployed",
    label: "UnEmployeed",
  },
];

const countries = [
  {
    value: "None",
    label: "Loan Type",
  },
  {
    value: "Topup",
    label: "Top Up",
  },
  {
    value: "Self Refined",
    label: "Self Refined",
  },
  {
    value: "TakeOver",
    label: "TakeOver",
  },
];

export default function LoanDetails(props) {
  const {
    formField: {
      loanType,
      loanAmount,
      loanTenure,
      carName,
      position,
      vechileDetails,
    },
    selectedTab,
  } = props;

  const { values, setFieldValue } = useFormikContext();

  const handleLoanTenureChange = (value) => {
    setFieldValue(loanTenure.name, value);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom></Typography>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {selectedTab === 2 ? null : (
          <Grid item xs={12} sm={6}>
            <SelectField
              name={loanType.name}
              label={loanType.label}
              data={countries}
              style={{
                width: "300px",
                height: "60px",
                background: "black",
                color: "white",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <SelectField
            name={position.name}
            label={position.label}
            data={cities}
            style={{
              width: "300px",
              height: "60px",
              background: "black",
              color: "white",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={carName.name}
            placeholder={carName.label}
            style={{
              width: "300px",
              height: "60px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              textAlign: "center",
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={loanAmount.name}
            placeholder={loanAmount.label}
            style={{
              width: "300px",
              height: "60px",
              background: "black",
              color: "white",
              border: "2px solid #407BFF",
              borderRadius: "20px",
              textAlign: "center",
            }}
          />
        </Grid>
        {selectedTab === 1 ? (
          <Grid item xs={12} sm={6}>
            <InputField
              name={vechileDetails.name}
              placeholder={vechileDetails.label}
              style={{
                width: "300px",
                height: "60px",
                background: "black",
                color: "white",
                border: "2px solid #407BFF",
                borderRadius: "20px",
                textAlign: "center",
              }}
            />
          </Grid>
        ) : null}

        <FormControl>
          <RadioGroup
            value={values[loanTenure.name]}
            onChange={(event) => handleLoanTenureChange(event.target.value)}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {loanTenure.options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#407BFF",
                      },
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
