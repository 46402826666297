import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./about.css";
import stella from "./stella.png";
import rajesh from "./rajesh.png";
import imag from "./set.png";
import btn from "./btn.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const navigate = useNavigate();
  const toggleDropdown = (id) => {
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id] || false,
    }));
  };

  return (
    <div className="about">
      <div className="main">
        <p className="aadya">AAdhya Associates</p>
      </div>
      <div className="whowe">
        <div className="lefti">
          <img src={imag} alt="dot" />
        </div>
        <div className="para1">
          <h1>
            who <strong style={{ background: "#407BFF" }}>we are</strong>
          </h1>
          <p className="who1">
            Aadhya Associates specializes in providing dedicated and tailored
            Car Loan services. Our focus encompasses a comprehensive range,
            catering to both new and used cars. Evaluating eligibility criteria,
            we propose optimal quotes with low-interest rates
          </p>
          <button className="applyb" onClick={() => navigate("/loan")}>
            Apply
          </button>
        </div>
      </div>
      <div className="meet">
        <h1 className="head">
          Meet <strong style={{ background: "#407BFF" }}>Our Founders </strong>
        </h1>
        <div className="founders">
          {/* <div className="founder">
            <img src={stella} alt="founder1" />
            <p className="name">Stella</p>
            <p className="designation">Ceo</p>
            <p className="paracard">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when It is a long established fact
              that a reader will be distracted by the readable content of a page
              when It is a long established fact that a reader
            </p>
          </div> */}
          <div className="founder">
            <img src={rajesh} alt="founder1" />
            <p className="name">Koteshwa Rao</p>
            <p className="designation">Founder</p>
            <p className="paracard">
              Meet Koteshwara Rao, the visionary behind Aadhya Associates,
              pioneering car loan solutions since 2016. With extensive expertise
              in car loans, Rao brings unparalleled insight to the industry. His
              commitment to empowering clients with financial freedom
              underscores Aadhya Associates' reputation for reliability and
              excellence in the realm of auto financing.
            </p>
          </div>
        </div>{" "}
      </div>
      <div className="frequent">
        <div className="faq">
          <h1 className="matter">Frequently Asked Questions</h1>
          <p className="sub">
            {" "}
            Let's explore how we can help your business thrive in the
            ever-evolving marketing landscape.
          </p>
          <button className="faqb" onClick={() => navigate("/loan")}>
            Apply Now <img src={btn} alt="" />{" "}
          </button>
        </div>
        <div className="drop">
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown1")}
            >
              What is a car loan, and how does it work?
            </button>
            {dropdownVisibility["dropdown1"] ? (
              <div class="dropdown-content">
                <p>
                  A car loan is a financial product that allows individuals to
                  borrow money for the purpose of purchasing a vehicle. The
                  borrower repays the loan amount in installments over a
                  specified period, usually with interest.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown2")}
            >
              Do I need collateral for a car loan from Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown2"] ? (
              <div class="dropdown-content">
                <p>
                  Typically, the car being financed serves as the collateral for
                  the loan. However, it's always best to check the specific
                  terms and conditions of the loan agreement.
                </p>
              </div>
            ) : null}
          </div>

          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown3")}
            >
              Can I get a loan to purchase a used car through Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown3"] ? (
              <div class="dropdown-content">
                <p>
                  Yes, Aadhya Associates provides loans for both new and used
                  cars, offering flexibility to clients based on their
                  preferences and requirements.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown4")}
            >
              What factors determine the eligibility for a car loan?
            </button>
            {dropdownVisibility["dropdown4"] ? (
              <div class="dropdown-content">
                <p>
                  Eligibility is often based on factors such as income, credit
                  score, employment stability, and the borrower's ability to
                  repay the loan.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown5")}
            >
              How long does it take to get approval for a car loan?
            </button>
            {dropdownVisibility["dropdown5"] ? (
              <div class="dropdown-content">
                <p>
                  The approval timeline may vary, but we strive to ensure a
                  quick and efficient process. The completeness of documentation
                  and meeting eligibility criteria play a significant role.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown6")}
            >
              Can I apply for a car loan if I have a low credit score?
            </button>
            {dropdownVisibility["dropdown6"] ? (
              <div class="dropdown-content">
                <p>
                  Aadhya Associates works with clients across various credit
                  scores. While a higher credit score may offer more favorable
                  terms, individuals with lower credit scores may still be
                  eligible for a car loan.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown7")}
            >
              Are there any hidden fees associated with car loans from Aadhya
              Associates?
            </button>
            {dropdownVisibility["dropdown7"] ? (
              <div class="dropdown-content">
                <p>
                  We are transparent about our terms and conditions. Our
                  commitment is to ensure a clear understanding of all fees and
                  charges associated with the car loan, and we do not impose
                  hidden fees.
                </p>
              </div>
            ) : null}
          </div>
          <div class="dropdown">
            <button
              class="dropdown-button"
              onClick={() => toggleDropdown("dropdown8")}
            >
              How does the car loan process work with Aadhya Associates?
            </button>
            {dropdownVisibility["dropdown8"] ? (
              <div class="dropdown-content">
                <p>
                  We facilitate all types of New & Used car loans, Car loan
                  Takeovers, and Cash on Car transactions. Our process is
                  streamlined and customer-friendly.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
