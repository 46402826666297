import React, { useState } from "react";
import "./style.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  transform: "translate(0%, 0%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overFlow: "scroll",
  "& .css-79ws1d-MuiModal-root": {
    overFlow: "scroll",
  },
};
export const HowItWorks = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen} sx={{ color: "white" }}>
        How It Works
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box sx={style}>
          <div className="how">
            <div className="how-out">
              <div className="frame">
                <div className="one">
                  <div className="application">
                    Application <p>step 1</p>
                  </div>
                  <p className="appchild">
                    Begin by filling out a simple online application. Provide
                    basic personal and financial details to kickstart the
                    process.
                  </p>
                </div>
                <div className="one">
                  <p className="appchild">
                    Our team will guide you through the necessary documentation.
                    Submit required proofs of identity, income, and other
                    relevant documents for a smooth verification process.
                  </p>
                  <div className="application">
                    Documentation <p>step-2</p>
                  </div>
                </div>
                <div className="one">
                  <div className="application">
                    Approval <p>step-3</p>
                  </div>
                  <p className="appchild">
                    Once your documents are verified, our swift approval process
                    ensures you get a quick decision. Count on us to provide a
                    response promptly.
                  </p>
                </div>
                <div className="one">
                  <p className="appchild">
                    With the loan approved, the final step is the disbursement
                    of funds. Receive the required financial support promptly,
                    allowing you to drive away in your new car with confidence.
                    Trust our efficient and transparent process for a seamless
                    car loan ex
                  </p>
                  <div className="application">
                    Disbursement <p>step-4</p>
                  </div>
                </div>
                <div className="close">
                  <Button onClick={handleClose} sx={{ color: "white" }}>
                    close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
