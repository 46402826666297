import React from "react";
import { useWindowWidth } from "../../breakPoint";

import { BtnIcons8 } from "../../components/icons/BtnIcons8";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const BlogPost = (props) => {
  const screenWidth = useWindowWidth();
  const { blogId } = useParams();
  const { state } = useLocation();
  const { blog } = state || {};
const navigate = useNavigate()
  console.log(blogId);
  console.log(blog);
  return (
    <div
      className="blog-post"
      style={{
        backgroundColor:
          screenWidth < 768
            ? "#000000"
            : screenWidth >= 1440 || (screenWidth >= 768 && screenWidth < 1440)
            ? "1E1E1E"
            : undefined,
      }}
    >
      <div
        className="blog-details"
        style={{
          background:
            screenWidth >= 1440 || (screenWidth >= 768 && screenWidth < 1440)
              ? "radial-gradient(50% 50% at 50% 50%, rgb(0, 78.77, 255) 0%, rgb(2.16, 26.02, 120.83) 55.64%, rgba(0, 0, 0, 0) 100%)"
              : undefined,
          backgroundColor:
            screenWidth < 768
              ? "#000000"
              : screenWidth >= 1440 ||
                (screenWidth >= 768 && screenWidth < 1440)
              ? "rgba(12,12,12)"
              : undefined,
          height:
            screenWidth < 768
              ? "1550px"
              : screenWidth >= 768 && screenWidth < 1440
              ? "2060.68px"
              : screenWidth >= 1440
              ? "3558px"
              : undefined,
          width:
            screenWidth < 768
              ? "360px"
              : screenWidth >= 768 && screenWidth < 1440
              ? "800px"
              : screenWidth >= 1440
              ? "1440px"
              : undefined,
        }}
      >
        <div
          className="overlap-3"
          style={{
            height:
              screenWidth < 768
                ? "141px"
                : screenWidth >= 768 && screenWidth < 1440
                ? "310px"
                : screenWidth >= 1440
                ? "536px"
                : undefined,
            left:
              screenWidth < 768
                ? "0"
                : screenWidth >= 768 && screenWidth < 1440
                ? "58px"
                : screenWidth >= 1440
                ? "100px"
                : undefined,
            top:
              screenWidth < 768
                ? "47px"
                : screenWidth >= 768 && screenWidth < 1440
                ? "119px"
                : screenWidth >= 1440
                ? "206px"
                : undefined,
            width:
              screenWidth < 768
                ? "360px"
                : screenWidth >= 768 && screenWidth < 1440
                ? "718px"
                : screenWidth >= 1440
                ? "1240px"
                : undefined,
          }}
        >
          <img
            className="rectangle-3"
            style={{
              height:
                screenWidth < 768
                  ? "116px"
                  : screenWidth >= 768 && screenWidth < 1440
                  ? "232px"
                  : screenWidth >= 1440
                  ? "400px"
                  : undefined,
              top:
                screenWidth < 768
                  ? "25px"
                  : screenWidth >= 1440 ||
                    (screenWidth >= 768 && screenWidth < 1440)
                  ? "0"
                  : undefined,
              width:
                screenWidth < 768
                  ? "360px"
                  : screenWidth >= 768 && screenWidth < 1440
                  ? "718px"
                  : screenWidth >= 1440
                  ? "1240px"
                  : undefined,
            }}
            alt="Rectangle"
            src={blog.image}
          />

          {(screenWidth >= 1440 ||
            (screenWidth >= 768 && screenWidth < 1440)) && (
            <>
              <div
                className="text-wrapper-12"
                style={{
                  fontSize:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "29px"
                      : screenWidth >= 1440
                      ? "50px"
                      : undefined,
                  height:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "58px"
                      : screenWidth >= 1440
                      ? "100px"
                      : undefined,
                  lineHeight:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "57.9px"
                      : screenWidth >= 1440
                      ? "100px"
                      : undefined,
                  top:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "231px"
                      : screenWidth >= 1440
                      ? "480px"
                      : undefined,
                }}
              >
                {blog.title}
              </div>
             
            </>
          )}
        </div>
        {(screenWidth >= 1440 ||
          (screenWidth >= 768 && screenWidth < 1440)) && (
          <>
            <p
              className="it-is-a-long"
              style={{
                fontSize:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "18.5px"
                    : screenWidth >= 1440
                    ? "32px"
                    : undefined,
                height:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "1184px"
                    : screenWidth >= 1440
                    ? "2048px"
                    : undefined,
                left:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "58px"
                    : screenWidth >= 1440
                    ? "100px"
                    : undefined,
                lineHeight:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "37.1px"
                    : screenWidth >= 1440
                    ? "64px"
                    : undefined,
                top:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "449px"
                    : screenWidth >= 1440
                    ? "775px"
                    : undefined,
                width:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "718px"
                    : screenWidth >= 1440
                    ? "1240px"
                    : undefined,
              }}
            >
              {blog.content}
            </p>
            <button className="btn" onClick={()=>navigate("/loan")}>Apply Now</button>
            {/* <div
              className="div-o-doanii-2"
              style={{
                borderRadius:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "2.32px 0px 0px 2.32px"
                    : screenWidth >= 1440
                    ? "4px 0px 0px 4px"
                    : undefined,
                height:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "56px"
                    : screenWidth >= 1440
                    ? "97px"
                    : undefined,
                left:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "747px"
                    : screenWidth >= 1440
                    ? "1290px"
                    : undefined,
                top:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "353px"
                    : screenWidth >= 1440
                    ? "610px"
                    : undefined,
                width:
                  screenWidth >= 768 && screenWidth < 1440
                    ? "87px"
                    : screenWidth >= 1440
                    ? "150px"
                    : undefined,
              }}
            >
              <div
                className="text-wrapper-14"
                style={{
                  fontSize:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "6.9px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                  height:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "9px"
                      : screenWidth >= 1440
                      ? "16px"
                      : undefined,
                  left:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "8px"
                      : screenWidth >= 1440
                      ? "13px"
                      : undefined,
                  top:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "9px"
                      : screenWidth >= 1440
                      ? "15px"
                      : undefined,
                  width:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "63px"
                      : screenWidth >= 1440
                      ? "109px"
                      : undefined,
                }}
              >
                Buying a New Car?
              </div>
              <p
                className="text-wrapper-15"
                style={{
                  fontSize:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "6.9px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                  height:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "18px"
                      : screenWidth >= 1440
                      ? "30px"
                      : undefined,
                  left:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "7px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                  top:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "20px"
                      : screenWidth >= 1440
                      ? "35px"
                      : undefined,
                  width:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "80px"
                      : screenWidth >= 1440
                      ? "138px"
                      : undefined,
                }}
              >
                Leave a massage on Watsapp call
              </p>
              <div
                className="text-wrapper-16"
                style={{
                  fontSize:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "6.9px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                  height:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "9px"
                      : screenWidth >= 1440
                      ? "15px"
                      : undefined,
                  left:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "8px"
                      : screenWidth >= 1440
                      ? "13px"
                      : undefined,
                  top:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "40px"
                      : screenWidth >= 1440
                      ? "69px"
                      : undefined,
                }}
              >
                000-000-0000
              </div>
              <img
                className="img-3"
                style={{
                  height:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "7px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                  left:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "75px"
                      : screenWidth >= 1440
                      ? "130px"
                      : undefined,
                  top:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "5px"
                      : screenWidth >= 1440
                      ? "8px"
                      : undefined,
                  width:
                    screenWidth >= 768 && screenWidth < 1440
                      ? "7px"
                      : screenWidth >= 1440
                      ? "12px"
                      : undefined,
                }}
                alt="Img"
                src={
                  screenWidth >= 768 && screenWidth < 1440
                    ? "https://cdn.animaapp.com/projects/65964703eafdb58ab4b00040/releases/6597a104c3042f8262f6ec5e/img/img-6.svg"
                    : screenWidth >= 1440
                    ? "https://cdn.animaapp.com/projects/65964703eafdb58ab4b00040/releases/6597a104c3042f8262f6ec5e/img/img-1.svg"
                    : undefined
                }
              />
            </div> */}
          </>
        )}

        {screenWidth < 768 && (
          <>
            <div className="frame-6">
              <div className="text-wrapper-17">{blog.title}</div>
            </div>
            <p className="it-is-a-long-2">{blog.content}</p>
            <button className="button">
              <div className="btn-text-5">Apply Now</div>
              <BtnIcons8 className="btn-icons" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
