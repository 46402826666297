import * as Yup from "yup";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
const {
  formField: {
    carName,
    loanAmount,
    firstName,
    lastName,
    phoneNumber,
    dob,
    email,
    address,
    state,
    zipcode,
    city,
  },
} = CheckoutModal;

export default [
  Yup.object().shape({

    [carName.name]: Yup.string()
      .nullable()
      .required(`${carName.requiredErrorMsg}`),
    [loanAmount.name]: Yup.string()
      .required(`${loanAmount.requiredErrorMsg}`)
      .test(
        "len",
        `${loanAmount.invalidErrorMsg}`,
        (val) => val && val.length >= 5
      ),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [phoneNumber.name]: Yup.string().required(
      `${phoneNumber.requiredErrorMsg}`
    ),

    [dob.name]: Yup.date().required(`${dob.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email("Invalid email")
      .required(`${email.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        "len",
        `${zipcode.invalidErrorMsg}`,
        (val) => val && val.length === 6
      ),
  }),
];
