import React, { useState } from "react";
import "./service.css";

import serviceLand from "../../assets/images/serviceland.png";
import steering from "../../assets/images/steering.png";
import about1 from "../../assets/images/about1.png";
import idfc from "../../assets/images/idfc.png";
import finc from "../../assets/images/fincorp.png";
import axis from "../../assets/images/axis.png";
import hdfc from "../../assets/images/hdfc.png";
import yes from "../../assets/images/yes.png";
import touch from "../../assets/images/touch.png";
import btn from "../../assets/images/btn.png";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const navigate = useNavigate();
  const toggleDropdown = (id) => {
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id] || false,
    }));
  };
  return (
    <div className="services">
      <div className="landing">
        <img src={serviceLand} alt="service" className="serviceimg" />
        <div className="landing-sc">
          <p className="landing-line">
            Explore Features And Benefits Of the <strong> Car Loan</strong>
          </p>
          <div className="tabs">
            <div className="tab-1">
              <img src={steering} alt="steering" />
              <p className="p-head">Used Car Purchase Loan</p>
            </div>
            <div className="tab-1">
              <img src={steering} alt="steering" />
              <p className="p-head">Auto Loan/New Car Loan</p>
            </div>
            <div className="tab-1">
              <img src={steering} alt="steering" />
              <p className="p-head">Used Car Re-finance Loan</p>
            </div>
          </div>
        </div>
        <div className="services-about">
          <div className="service-about-left">
            <p className="about-left">About</p>
            <p className="about-para">
              Aadhya Associates specializes in providing dedicated and tailored
              Car Loan services. Our focus encompasses a comprehensive range,
              catering to both new and used cars. Evaluating eligibility
              criteria, we propose optimal quotes with low-interest rates. With
              affiliations to major banks, our services ensure seamless access
              to customized car loans. Aadhya Associates, your trusted partner
              in Car Loans
            </p>
            <button className="about-button" onClick={() => navigate("/loan")}>
              Apply now
            </button>
          </div>
          <img src={about1} alt="about" />
        </div>
        <div className="bank">
          <p className="banking"># Banking Partners</p>
          <div className="all-banks">
            <div className="banks">
              <img src={hdfc} alt="hdfc" />
              <img src={idfc} alt="hdfc" />
              <img src={axis} alt="hdfc" />
              <img src={finc} alt="hdfc" />
              <img src={yes} alt="hdfc" />
            </div>
            <div className="banks">
              <img src={hdfc} alt="hdfc" />
              <img src={idfc} alt="hdfc" />
              <img src={axis} alt="hdfc" />
              <img src={finc} alt="hdfc" />
              <img src={yes} alt="hdfc" />
            </div>
          </div>
        </div>
        <div className="touch-main">
          <img src={touch} alt="touch" />
          <div className="touch-right">
            <p className="get-in">get In Touch With your Mail</p>
            <div className="form-in">
              <input type="email" placeholder="Enter your email address" />
              <button>Subscribe</button>
            </div>
          </div>
        </div>
        <div className="frequent">
          <div className="faq">
            <h1 className="matter">Frequently Asked Questions</h1>
            <p className="sub">
              {" "}
              Let's explore how we can help your business thrive in the
              ever-evolving marketing landscape.
            </p>
            <button className="faqb" onClick={() => navigate("/loan")}>
              Apply Now <img src={btn} alt="" />{" "}
            </button>
          </div>
          <div className="drop">
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown1")}
              >
                What is a car loan, and how does it work?
              </button>
              {dropdownVisibility["dropdown1"] ? (
                <div class="dropdown-content">
                  <p>
                    A car loan is a financial product that allows individuals to
                    borrow money for the purpose of purchasing a vehicle. The
                    borrower repays the loan amount in installments over a
                    specified period, usually with interest.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown2")}
              >
                Do I need collateral for a car loan from Aadhya Associates?
              </button>
              {dropdownVisibility["dropdown2"] ? (
                <div class="dropdown-content">
                  <p>
                    Typically, the car being financed serves as the collateral
                    for the loan. However, it's always best to check the
                    specific terms and conditions of the loan agreement.
                  </p>
                </div>
              ) : null}
            </div>

            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown3")}
              >
                Can I get a loan to purchase a used car through Aadhya
                Associates?
              </button>
              {dropdownVisibility["dropdown3"] ? (
                <div class="dropdown-content">
                  <p>
                    Yes, Aadhya Associates provides loans for both new and used
                    cars, offering flexibility to clients based on their
                    preferences and requirements.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown4")}
              >
                What factors determine the eligibility for a car loan?
              </button>
              {dropdownVisibility["dropdown4"] ? (
                <div class="dropdown-content">
                  <p>
                    Eligibility is often based on factors such as income, credit
                    score, employment stability, and the borrower's ability to
                    repay the loan.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown5")}
              >
                How long does it take to get approval for a car loan?
              </button>
              {dropdownVisibility["dropdown5"] ? (
                <div class="dropdown-content">
                  <p>
                    The approval timeline may vary, but we strive to ensure a
                    quick and efficient process. The completeness of
                    documentation and meeting eligibility criteria play a
                    significant role.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown6")}
              >
                Can I apply for a car loan if I have a low credit score?
              </button>
              {dropdownVisibility["dropdown6"] ? (
                <div class="dropdown-content">
                  <p>
                    Aadhya Associates works with clients across various credit
                    scores. While a higher credit score may offer more favorable
                    terms, individuals with lower credit scores may still be
                    eligible for a car loan.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown7")}
              >
                Are there any hidden fees associated with car loans from Aadhya
                Associates?
              </button>
              {dropdownVisibility["dropdown7"] ? (
                <div class="dropdown-content">
                  <p>
                    We are transparent about our terms and conditions. Our
                    commitment is to ensure a clear understanding of all fees
                    and charges associated with the car loan, and we do not
                    impose hidden fees.
                  </p>
                </div>
              ) : null}
            </div>
            <div class="dropdown">
              <button
                class="dropdown-button"
                onClick={() => toggleDropdown("dropdown8")}
              >
                How does the car loan process work with Aadhya Associates?
              </button>
              {dropdownVisibility["dropdown8"] ? (
                <div class="dropdown-content">
                  <p>
                    We facilitate all types of New & Used car loans, Car loan
                    Takeovers, and Cash on Car transactions. Our process is
                    streamlined and customer-friendly.
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
